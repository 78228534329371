body {
    margin: 0;
    background-color: black;
    font-family: Arial, Helvetica, sans-serif, proxima;
}

@font-face {
    font-family: proxima;
    src: url(../public/css/fonts/ProximaNova-Regular.woff);
}

@font-face {
    font-family: proxima-bold;
    src: url(../public/css/fonts/ProximaNova-Bold.woff);
}

@font-face {
    font-family: proxima-semi-bold;
    src: url(../public/css/fonts/ProximaNova-Semibold.woff);
}

@font-face {
    font-family: proxima-medium;
    src: url(../public/css/fonts/ProximaNova-Medium.woff);
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px #fff, 0 0 20px #fff;
    }
    to {
        box-shadow: 0 0 20px #fff, 0 0 30px;
    }
}

.disable-scrollbars::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    width: 0px;
}

.disable-scrollbars {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}

.silhouette img {
    -webkit-filter: grayscale(100%) brightness(0);
    filter: grayscale(100%) brightness(0);
    opacity: 0.8;
}

/* .osano-cm-dialog {
    border-radius: 4px;
    bottom: 30px !important;
    left: 30px !important;
    overflow: visible;
} */

/* .osano-cm-dialog::after {
    content: '';
    background-color: transparent;
    position: absolute;
    left: 0px;
    bottom: -15px;
    width: 50px;
    height: 30px;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    background: #f5f5f5;
    z-index: 1000000 !important;
} */

.whyMattersButton {
    all: unset;
}

.whyMattersButton:focus {
    background-color: rgb(226, 226, 226);
    border-radius: 4px;
}

.globalSettings:focus {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scrollThroughBagButton:focus-visible {
    background-color: rgba(255, 255, 255, 0.22);
    opacity: 1;
}
.footerButton {
    border-bottom: 2px solid transparent;
}

.footerButton:focus {
    border-bottom: 2px solid black;
}

.clubButton:focus-visible {
    outline: 2px solid rgba(255, 255, 255, 0.445);
}
